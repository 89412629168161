/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "ngx-toastr/toastr";

@import "@futura/futura-ui/styles/root";
@import "@futura/futura-ui/styles/variable/color";
@import "@futura/futura-ui/styles/variable/breakpoints";
@import "@futura/futura-ui/styles/variable/spacing";
@import "@futura/futura-ui/styles/cursor";
@import "@futura/futura-ui/styles/chip";
@import "@futura/futura-ui/styles/spacing";
@import "global-styles";

@import "app/styles/text";
@import "app/styles/skeleton";
@import "app/styles/components";
@import "app/styles/buttons";
@import "app/styles/cards";
@import "app/styles/angular-cdk-customization";
@import "app/styles/material-customization";
@import "app/styles/background";
@import "app/styles/bootstrap-customization";
@import "app/styles/table";
@import "app/styles/swiper-customization";
@import "app/styles/form";

* {
  outline: none !important;
}

html, body {
  font-family: $fut-font-family !important;
  font-size: 14px !important;
  color: $fut-primary-500;
  height: 100svh;
}

body > * {
  overflow: auto !important;
  overflow-x: hidden !important;
}

@media print {
  html, body {
    display: none !important;
  }
}

body {
  background: $fut-secondary-100;
}

.sticky-top {
  z-index: 10;
}

.progress {
  height: 0.5em;
  background-color: $fut-secondary-400 !important;
  border-radius: 0 0.25rem 0.25rem 0;
}

.progress-bar {
  transition: background-color 0.3s ease-in-out;
  background-color: $fut-primary-500 !important;

  &.bg-danger {
    background-color: $fut-red-900 !important;
  }
}

.default-pointer {
  cursor: default;
}

.maxh-75 {
  max-height: 75vh;

  @media screen and (max-width: $mobile) {
    max-height: 50vh;
  }
}

.overlay-blur {
  width: calc(100% - 76px);
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10;
  backdrop-filter: blur(10px) !important;
  background-color: rgba(0, 0, 0, 0.5) !important;

  @media screen and (max-width: 991px) {
    width: 100%;
    padding-right: 0;
    top: 84px;
    height: calc(100vh - 84px);
  }
}

.vh-100 {
  min-height: 100vh;
  transition: all 0.2s ease-in-out;
}

.rounded-xl {
  border-radius: 2em !important;
}

.prewrap {
  white-space: pre-wrap;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-1 {
  opacity: 1 !important;
}

.line-height-1 {
  line-height: 1em;
}

.mb-3-5, .m-3-5, .my-3-5 {
  margin-bottom: 1.25rem;
}

.mt-3-5, .m-3-5, .my-3-5 {
  margin-top: 1.25rem;
}

.mr-3-5, .m-3-5, .mx-3-5 {
  margin-right: 1.25rem;
}

.ml-3-5, .m-3-5, .mx-3-5 {
  margin-left: 1.25rem;
}

.fut-hr {
  margin: 0.5em 0;
  background: rgba($fut-secondary-800-rgb, 0.3);
}

hr {
  border-top-color: $fut-secondary-200;
}

.ngx-toastr-one-line {
  min-width: fit-content !important;
  white-space: nowrap !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

/* total width */
.scrollbar-mac::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.scrollbar-mac::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.scrollbar-mac::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar-mac::-webkit-scrollbar-button {
  display: none;
}

.fut-emoji {
  aspect-ratio: 1 / 1;
  width: 3em;
}

.toast-container {
  // Permette ai tost di essere mostrati sopra usersnap
  z-index: 2147483650 !important;
}

.question-text, .explanation, .simulation-long-text-container {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.hover-highlight {
  &:hover {
    background-color: $fut-secondary-50 !important;
    border-radius: 1em;
  }
}

.fut-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: $bg-image no-repeat;
  background-size: cover;

  @media screen and (max-width: 991px) {
    transform: translateX(-50%);
    width: 200%;
  }

  &.pointer {
    cursor: not-allowed !important;
  }
}

.rounded {
  border-radius: 1em !important;
}

div:has(iframe[title="La verifica reCAPTCHA scade tra due minuti"]) {
  z-index: ($dialog-z-index * 5) !important;
}

.fill-mat-icon {
  font-variation-settings: 'FILL' 1;
}


.fut-toast-light-error {
  background-image: none !important;
  padding: 15px !important;
  background-color: $fut-red-100 !important;
  color: $fut-red-900 !important;
  box-shadow: none !important;

  font-size: 14px;
  line-height: 20px;
}

.alice-bg {
  background: linear-gradient(100deg, #1B213C 0%, #003C70 100%);
}

.fut-primary-200 {
  color: $fut-primary-200
}

.fut-secondary-200 {
  color: $fut-secondary-200
}

.fut-secondary-500 {
  color: $fut-secondary-500
}

.container-fluid.max-container-fluid {
  max-width: 1180px;
}

.fw-bold {
  font-weight: bold;
}